import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const parseJwt = (token) => {
    try
    {
        return JSON.parse(atob(token.split(".")[1]));
    }
    catch (e)
    {
        return null;
    }
};

export const AuthProvider = ({ children }) => {
    // State to hold the authentication token
    const navigate          = useNavigate();

    const [token, setToken] = useState(() => {
        const user = JSON.parse(localStorage.getItem("token"));
        if (user)
        {
            const decodedJwt = parseJwt(user.accessToken);

            if (decodedJwt.exp * 1000 < Date.now())
            {
                navigate("/sign-in", { replace: true });
                return null;
            }

            return localStorage.getItem("token");
        }
    });


    // call this function when you want to authenticate the user
    const login = (data) => {
        setToken(data);
        navigate("/dashboard");
    };

    // call this function to sign out logged in user
    const logout = () => {
        setToken(null);
        navigate("/sign-in", { replace: true });
    };

    useEffect(() => {
        if (token)
        {
            localStorage.setItem('token', token);
        }
        else
        {
            localStorage.removeItem('token')
        }
    }, [token]);

    const value = useMemo(
        () => ({
            token,
            login,
            logout,
        }),
        [token]
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
