import React from "react";

import BoschNavigate from '../../images/cd_bosch_supergraphic.jpg';
import BoschLogo from '../../images/Bosch-logo.svg';
import './Headerbar.css';

function HeaderBar(props) {
    const { headerBarContent } = props;

    return (
        <>
            <div className='header__image-container' >
                <img className='header__image' src={ BoschNavigate } alt='Bosch-navigate' />
            </div>
            <div className='header__title-container'>
                <div className='header__page-name'>{ headerBarContent }</div>
                <div className='header__project-name'>Cloud Diagnostic Project</div>
                <div>
                    <img className='header__logo-image' src={ BoschLogo } alt='Bosch-logo' />
                </div>
            </div>
        </>
    )
}

export { HeaderBar };
