import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faFileCode, faTablet, faUser } from '@fortawesome/free-solid-svg-icons';

import { HeaderBar } from '../../components/headerbar/Headerbar';
import { DTCSetting, DeviceSetting, UserSetting } from '../../components/setting/setting.component';

import '../../App.css';
import './setting.css'

function Setting(props) {
    const [activedComponent, setActivedComponent]   = useState(0);

    return (
        <>
            <HeaderBar headerBarContent="Setting"/>
            <div className='setting-page-container'>
                <div className='setting-board-container'>
                    <div className='setting-left-board'>
                        <div className='setting-car-content'>
                                <div className={ (activedComponent === 1) ? 'setting-info-container setting-info-actived' : 'setting-info-container'} onClick={() => {
                                    setActivedComponent(1);
                                }}>
                                    <FontAwesomeIcon icon={faUser} />
                                    <div className='setting-info-title'>User</div>
                                </div>
                                <div className={ (activedComponent === 2) ? 'setting-info-container setting-info-actived' : 'setting-info-container'} onClick={() => {
                                    setActivedComponent(2);
                                }}>
                                    <FontAwesomeIcon icon={faTablet} />
                                    <div className='setting-info-title'>Device</div>
                                </div>
                                <div className={ (activedComponent === 3) ? 'setting-info-container setting-info-actived' : 'setting-info-container'} onClick={() => {
                                    setActivedComponent(3);
                                }}>
                                    <FontAwesomeIcon icon={faFileCode} />
                                    <div className='setting-info-title'>DTC Data</div>
                                </div>
                        </div>
                    </div>
                    <div className='setting-central-board'>
                        {
                            (activedComponent === 0) ?
                            (
                                <div className='setting-table-container'>
                                    <div className='diagnostic-info-container'>
                                        <FontAwesomeIcon icon={faCubes} />
                                        <div className='diagnostic-info-title'>Select component</div>
                                    </div>
                                </div>
                            ):
                            ((activedComponent === 1) ?
                            (<UserSetting activedComponent={activedComponent} />):
                            ((activedComponent === 2) ?
                            (<DeviceSetting activedComponent={activedComponent} />):
                            ((activedComponent === 3) ?
                            (<DTCSetting activedComponent={activedComponent} />):
                            (<></>))))
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Setting;
