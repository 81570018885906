import { useOutlet }    from "react-router-dom";

import AuthProvider     from "../../services/auth.service";

export const AuthLayout = () => {
    const outlet = useOutlet();

    return <AuthProvider>{outlet}</AuthProvider>;
};

export default AuthLayout;
