import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from '../../services/auth.service';

export const HomeLayout = () => {
    const { token }  = useAuth();

    if (token)
    {
        return <Navigate to='/dashboard' replace />;
    }

    return (
        <Outlet/>
    );
};

export default HomeLayout;
