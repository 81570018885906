import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faInfo, faPen, faXmark } from '@fortawesome/free-solid-svg-icons';

import { HeaderBar } from '../../components/headerbar/Headerbar';
import { InfoFWPopup, UpdateFWPopup, UploadFWPopup } from '../../components/popup/fw_popup';

import lightIcon from '../../images/headlight.png';
import bcmIcon from '../../images/sport-car.png';
import CGWIcon from '../../images/cgw-icon.png';
import engineIcon from '../../images/car-engine.png';

import './firmware.css';

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'};

const gridStyle = { fontSize: 13 }

function Firmware(props) {
    const [FWInfos, setFWInfos]                 = useState([]);
    const [latestFWInfos, setLatestFWInfos]     = useState([]);
    const [openInfo, setOpenInfo]               = useState({
        open: false,
        data:
        {
            description: '' 
        }
    });

    const [openEdit, setOpenEdit]               = useState({
        open: false,
        data:
        {
            majorVersion:   '',
            minorVersion:   '',
            patchVersion:   '',
            description:    '',
        }
    });

    const handleRemoveFW = (FWid) => {
        const toastId = toast.loading("Uploading...")

        fetch(`/api/firmware/${FWid}`, { method: 'DELETE' })
        .then((respose) => {
            respose.json()
            .then((result) => {
                if (result.status === 'success')
                {
                    fetchFWInfos();
                    toast.update(toastId, {
                        render: result.message,
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
                else
                {
                    toast.update(toastId, {
                        render: result.message,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
            })
        })
        .catch((respose) => {
            respose.json()
            .then((result) => {
                toast.update(toastId, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                })
            })
        })
    }

    const handleShowInfo = async (FWdata) => {
        setOpenInfo({
            open: true,
            data: FWdata
        })
    }

    const handleEditFW = async (FWdata) => {
        setOpenEdit({
            open: true,
            data: FWdata
        })
    }

    const FWcolumns = [
        {
            field: 'firmwareFile',
            headerName: 'Firmware filename',
            headerAlign: 'center',
            minWidth: 230,
        },
        {
            field: 'sequenceFile',
            headerName: 'Sequence filename',
            headerAlign: 'center',
            minWidth: 230,
        },
        {
            field: 'majorVersion',
            headerName: 'Major Version',
            align: 'center',
            headerAlign: 'center',
            minWidth: 170,
        },
        {
            field: 'minorVersion',
            headerName: 'Minor Version',
            align: 'center',
            headerAlign: 'center',
            minWidth: 170,
        },
        {
            field: 'patchVersion',
            headerName: 'Patch Version',
            align: 'center',
            headerAlign: 'center',
            minWidth: 170,
        },
        {
            field: 'releaseDate',
            headerName: 'Release date',
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'moduleFirmware',
            headerName: 'Module',
            align: 'center',
            headerAlign: 'center',
            minWidth: 180,
            renderCell: (values) => {
                if (values.row.moduleFirmware === 'light')
                {
                    return (
                        <div className='box-module box-green'>Light</div>
                    )
                }
                else if (values.row.moduleFirmware === 'bcm')
                {
                    return (
                        <div className='box-module box-blue'>BCM</div>
                    )
                }
                else if (values.row.moduleFirmware === 'cgw')
                {
                    return (
                        <div className='box-module box-orange'>Central Gateway</div>
                    )
                }
                else if (values.row.moduleFirmware === 'engine')
                {
                    return (
                        <div className='box-module box-yellow'>Engine</div>
                    )
                }
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            sortable: false,
            renderCell: (values) => {
                return (
                    <div className='action-container'>
                        <button className='action-button box-blue' onClick={() => handleShowInfo(values.row)}>
                            <FontAwesomeIcon icon={faInfo} />
                            <div className='action-info'>Info</div>
                        </button>
                        <button className='action-button box-yellow' onClick={() => handleEditFW(values.row)}>
                            <FontAwesomeIcon icon={faPen} />
                            <div className='action-info'>Edit</div>
                        </button>
                        <button className='action-button box-orange' onClick={() => handleRemoveFW(values.row._id)}>
                            <FontAwesomeIcon icon={faXmark} />
                            <div className='action-info'>Remove</div>
                        </button>
                    </div>
                )
            }
        }
    ];

    const fetchFWInfos = async () => {
        const data      = await fetch('/api/firmware');
        const FWdata    = await data.json();

        FWdata.map((data, index) => {
            let releaseDate = new Date(data.releaseDate);

            data['id'] = index;
            data['releaseDate'] = releaseDate.toLocaleString("en-US", dateOptions);
            return;
        });

        setFWInfos(FWdata);
    }

    const fetchLatestFWInfos = async () => {
        const data      = await fetch('/api/firmware/modules');
        const FWdata    = await data.json();

        FWdata.map((data, index) => {
            let releaseDate = new Date(data.releaseDate);

            data['id'] = index;
            data['releaseDate'] = releaseDate.toLocaleString("en-US", dateOptions);
            return;
        });

        setLatestFWInfos(FWdata);
    }

    useEffect(() => {
        fetchFWInfos();
        fetchLatestFWInfos();
    }, []);

    return (
        <>
            <HeaderBar headerBarContent="Firmware"/>
            <div className='firmware-page-container'>
                <div className='tag-container'>
                    {
                        latestFWInfos.map((FWInfo, index) => {
                            let title;
                            let icon;
                            let customClassname;

                            if (FWInfo.moduleFirmware === 'light')
                            {
                                title = 'Light';
                                icon  = lightIcon;
                                customClassname = 'tag-component-container'
                            }
                            else if (FWInfo.moduleFirmware === 'bcm')
                            {
                                title = 'Body control module';
                                icon  = bcmIcon;
                                customClassname = 'tag-component-container tag-component-disabled'
                            }
                            else if (FWInfo.moduleFirmware === 'cgw')
                            {
                                title = 'Central gateway';
                                icon  = CGWIcon;
                                customClassname = 'tag-component-container tag-component-disabled'
                            }
                            else if (FWInfo.moduleFirmware === 'engine')
                            {
                                title = 'Engine';
                                icon  = engineIcon;
                                customClassname = 'tag-component-container'
                            }
                            
                            return (
                                <div className={customClassname} key={index}>
                                    <div className='tag-component-title'>{ title }</div>
                                    <div className='tag-component-content-container'>
                                        <div className='tag-component-content-title'>ver {FWInfo.majorVersion}.{FWInfo.minorVersion}.{FWInfo.patchVersion}</div>
                                        <img className='tag-component-content-icon' src={ icon } alt='module icon'/>
                                    </div>
                                    <div className='tag-component-content-date'>
                                        <div>Release date: {FWInfo.releaseDate}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='main-content-container'>
                    {
                        (FWInfos.length !== 0) ?
                        (<DataGrid 
                            rows    = { FWInfos }
                            columns = { FWcolumns }
                            style   = { gridStyle }
                            autoPageSize 
                            disableRowSelectionOnClick
                        />) : 
                        (
                            <div className='diagnostic-info-container'>
                                <FontAwesomeIcon icon={faFolderOpen} />
                                <div className='diagnostic-info-title'>No data found</div>
                            </div>
                        )
                    }
                </div>
                <div className='firmware-button-container'>
                    <UploadFWPopup />
                </div>
                <Popup
                    open={openInfo.open}
                    closeOnDocumentClick
                    onClose={() => setOpenInfo({ ...openInfo, open: false})}
                >
                    <InfoFWPopup description={ openInfo.data.description } />
                </Popup>
                <Popup
                    open={openEdit.open}
                    closeOnDocumentClick
                    onClose={() => setOpenEdit({ ...openEdit, open: false})}
                >
                    <UpdateFWPopup data={ openEdit.data }/>
                </Popup>
            </div>
        </>
    );
}

export default Firmware;