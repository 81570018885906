import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import emailjs from "@emailjs/browser";
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faExclamation, faCircleExclamation, 
    faMagnifyingGlass, faWrench, faEnvelopeOpenText, faFolderOpen, faStop, faRecycle } from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';

import { HeaderBar } from '../../components/headerbar/Headerbar';
import { DiagnosticCar } from '../../components/diagnostic/DiagnosticItem';
import { Button } from '../../components/button/Button';
import { AdvanceToolPopup } from '../../components/popup/fw_popup';

import client from '../../setupMQTT';

import CarIcon from '../../images/car_diag.png';

import '../../App.css';
import './diagnostics.css';
import { useAuth } from '../../services/auth.service';

let diagVehicleMqtt;
let toastDiag;
let toastClrDTC;

const gridStyle = { fontSize: 13 }

const columns = [
    {
        field: 'description',
        headerName: 'Description',
        flex: 5,
        headerAlign: 'center'
    },
    {
        field: 'severity',
        headerName: 'Severity',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (values) => {
            if (values.row.severity === '3-high')
            {
                return (
                    <div className='box-error'>
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                        <div className='box-title'>High</div>
                    </div>
                )
            }
            else if (values.row.severity === '2-medium')
            {
                return (
                    <div className='box-warning'>
                        <FontAwesomeIcon icon={faExclamation} />
                        <div className='box-title'>Medium</div>
                    </div>
                )
            }
            else if (values.row.severity === '1-low')
            {
                return (
                    <div className='box-info'>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        <div className='box-title'>Low</div>
                    </div>
                )
            }
        }
    },
    {
        field: 'module',
        headerName: 'Module',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (values) => {
            if (values.row.module === 'Airbag')
            {
                return (
                    <div className='box-module box-green'>Airbag</div>
                )
            }
            else if (values.row.module === 'BCM')
            {
                return (
                    <div className='box-module box-blue'>BCM</div>
                )
            }
            else if (values.row.module === 'CGW')
            {
                return (
                    <div className='box-module box-orange'>Central Gateway</div>
                )
            }
            else if (values.row.module === 'Engine')
            {
                return (
                    <div className='box-module box-yellow'>Engine</div>
                )
            }
        }
    },
];

const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'};

function processDTCsRawData(rawData)
{
    let processedData   = [];
    let idx             = 0;
    let airbagDTCs      = rawData.Airbag;
    let BCMDTCs         = rawData.BCM;
    let CGWDTCs         = rawData.CGW;
    let EngineDTCs      = rawData.Engine;

    if (airbagDTCs.length)
    {
        airbagDTCs.forEach((airbagDTC, index) => {
            if (airbagDTC !== null)
            {
                airbagDTC['id']     = idx++;
                airbagDTC['module'] = 'Airbag';
                
                processedData.push(airbagDTC);
            }
        });
    }

    if (BCMDTCs.length)
    {
        BCMDTCs.forEach((bcmDTC, index) => {
            if (bcmDTC !== null)
            {
                bcmDTC['id']        = idx++;
                bcmDTC['module']    = 'BCM';
                
                processedData.push(bcmDTC);
            }
        });
    }

    if (CGWDTCs.length)
    {
        CGWDTCs.forEach((cgwDTC, index) => {
            if (cgwDTC !== null)
            {
                cgwDTC['id']        = idx++;
                cgwDTC['module']    = 'CGW';
                
                processedData.push(cgwDTC);
            }
        });
    }

    if (EngineDTCs.length)
    {
        EngineDTCs.forEach((engineDTC, index) => {
            if (engineDTC !== null)
            {
                engineDTC['id']     = idx++;
                engineDTC['module'] = 'Engine';
                
                processedData.push(engineDTC);
            }
        });
    }

    return processedData;
};

function genEmailReport(DTCsdata)
{
    let report = '<table>'
        + '<tr>'
        + '<th>Description</th>'
        + '<th>Severity</th>'
        + '<th>Module</th>'
        + '</tr>';

    DTCsdata.forEach((DTC, index) => {
        report += '<tr>'
            + `<td>${DTC.description}</td>`
            + `<td>${DTC.severity}</td>`
            + `<td>${DTC.module}</td>`
            + '</tr>'
    });

    report += '</table>';

    return report;
}

function Diagnostics(props)
{
    const { token }                 = useAuth();

    const [openAdvanceTool, setOpenAdvanceTool] = useState(false);
    const [vehicles, setVehicles]   = useState([]);

    const [diagInfos, setDiagInfos] = useState({
        isDiagRun:      false,
        dtcData:        {},
        DTCInfos:       [],
        diagVehicle:    ''
    });

    const [realtimeData, setRealtimeData]   = useState({
        engineSpeed: 0,
        vehicleSpeed: 0,
        powerSupply: 0
    });

    let diagnosticVehicles;

    const fetchVehicles = async () => {
        const data      = await fetch('/api/car/user/' + JSON.parse(token).id);
        const vehData   = await data.json();

        setVehicles(vehData);
    }

    const fetchDTCs     = async (vehicleID) => {
        const data      = await fetch(`/api/car/` + vehicleID + `/dtc`);
        const dtcData   = await data.json();

        let lastUpdated = new Date(dtcData.DTCErrors.date);

        const processedData = processDTCsRawData(dtcData.DTCErrors);
        
        /* Set diagnostic status */
        setDiagInfos({
            DTCInfos    : processedData,
            isDiagRun   : dtcData.isDiagnosticRunning,
            dtcData     : lastUpdated.toLocaleString("en-US", dateOptions),
            diagVehicle : vehicleID
        });
    }

    const triggerDiag   = (vehicleID) => {
        toastDiag = toast.loading('Triggering diagnostic...');

        fetch(`/api/car/` + vehicleID + `/trigger-diag`)
        .then((respose) => {
            respose.json()
            .then((result) => {
                if (result.status === 'success')
                {
                    setDiagInfos({ ...diagInfos, isDiagRun: true });
                    toast.update(toastDiag, {
                        render: result.message,
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
                else
                {
                    toast.update(toastDiag, {
                        render: result.message,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
            })
        })
        .catch((respose) => {
            respose.json()
            .then((result) => {
                toast.update(toastDiag, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                })
            })
        })
    }

    const triggerClearDiag  = (vehicleID) => {
        toastClrDTC = toast.loading('Clearing DTC...');

        fetch(`/api/car/` + vehicleID + `/trigger-clear`);
    }

    const triggerStopDiag = (vehicleID) => {
        toastDiag = toast.loading('Stopping diagnostic...');

        fetch(`/api/car/` + vehicleID + `/trigger-stop`)
        .then((respose) => {
            respose.json()
            .then((result) => {
                if (result.status === 'success')
                {
                    setDiagInfos({ ...diagInfos, isDiagRun: false });
                    toast.update(toastDiag, {
                        render: result.message,
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
                else
                {
                    toast.update(toastDiag, {
                        render: result.message,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
            })
        })
        .catch((respose) => {
            respose.json()
            .then((result) => {
                toast.update(toastDiag, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                })
            })
        });
    }

    const triggerRealtimeReading = (vehicleID) => {
        client.subscribe('car:actuator', { qos: 0 });
        client.subscribe('car:actual-value', { qos: 0 });
        client.publish(vehicleID, 'ReadActualValue', { qos: 0, retain: false });

        setOpenAdvanceTool(true);
    }

    const triggerSendReport = () => {
        const DTCReport = genEmailReport(diagInfos.DTCInfos);
        const toastReport = toast.loading("Sending report...");

        emailjs.send('service_b86kqtn', 'template_h7f8ndi', {
            to_email: 'huynguyencong98@gmail.com',
            cc_email: 'lduy2804@gmail.com',
            model_car: 'Honda City',
            registration_number: '50LD-120.75',
            VIN_number: 'RLHGM6666JY804968',
            full_name: 'Duy Nguyen',
            phone_number: '0909123456',
            DTC_report: DTCReport
        })
        .then((result) => {
            toast.update(toastReport, {
                render: 'Report is sent',
                type: 'success',
                isLoading: false,
                autoClose: 2000,
            })
        })
        .catch((error) => {
            console.log(error);
            toast.update(toastReport, {
                render: 'Something wrong',
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            })
        })
    }

    useEffect(() => {
        fetchVehicles();

        emailjs.init('vZj8PhRcnvZh5qLB3')

        client.on('message', function (topic, message, packet) {
            if (topic === 'car:diag')
            {
                let rawData = message.toString().split(':');
                
                if (diagVehicleMqtt === rawData[0]) 
                {
                    fetchDTCs(diagVehicleMqtt);
                }
            }
        })

        client.on('message', function (topic, message, packet) {
            if (topic === 'car:actual-value')
            {
                let rawData = message.toString().split(':');
                
                if (diagVehicleMqtt === rawData[0]) 
                {
                    setRealtimeData({
                        engineSpeed:    parseInt(rawData[2]),
                        vehicleSpeed:   parseInt(rawData[4]),
                        powerSupply:    parseInt(rawData[6]),
                    })
                }
            }
        })

    }, []);

    diagnosticVehicles = (vehicles.length === 0) ? undefined : (vehicles.car.length === 0) ? undefined : vehicles.car;
 
    return (
        <>
            <HeaderBar headerBarContent="Diagnostics"/>
            <div className='diagnostic-page-container'>
                <div className='diagnostic-board-container'>
                    <div className='diagnostic-left-board'>
                        <div className='diagnostic-car-title'>Cars Information</div>
                        <div className='diagnostic-car-content'>
                            {
                                (diagnosticVehicles !== undefined) ? 
                                    (diagnosticVehicles.map((item, index) => 
                                        <div key = {index} onClick={() => {
                                            diagVehicleMqtt = item.carID._id
                                            fetchDTCs(item.carID._id);
                                        }}>
                                            <DiagnosticCar CarItemContent={ item.carID } isActive = { diagInfos.diagVehicle === item.carID._id ? true : false } />
                                        </div>
                                    )) :
                                    (
                                        <div className='diagnostic-info-container'>
                                            <FontAwesomeIcon icon={faFolderOpen} />
                                            <div className='diagnostic-info-title'>No data found</div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                    <div className='diagnostic-central-board'>
                        <div className='diagnostic-table-container'>
                            {
                                (diagInfos.isDiagRun === true) ? 
                                (<div className='diagnostic-progress-container'>
                                    <img className='diagnostic-progress-icon' src={ CarIcon } alt='car-diagnostic' />
                                    <div className='diagnostic-progress-title'>Diagnostic is in-progress</div>
                                </div>
                                ) :
                                ((diagInfos.DTCInfos.length !== 0) ? 
                                    (<>
                                        {
                                            toast.update(toastClrDTC, {
                                                render: 'Cannot clear DTC error',
                                                type: 'error',
                                                isLoading: false,
                                                autoClose: 2000,
                                            })
                                        }
                                        <div className='diagnostic-time-title'>Last updated: {diagInfos.dtcData}</div>
                                        <DataGrid 
                                            initialState={{
                                                sorting: {
                                                    sortModel: [{ field: 'severity', sort: 'desc' }],
                                                },
                                            }}
                                            rows    = { diagInfos.DTCInfos }
                                            columns = { columns }
                                            style   = { gridStyle }
                                            checkboxSelection
                                            autoPageSize 
                                        />
                                    </>
                                    ) :
                                    (
                                        <div className='diagnostic-info-container'>
                                            <FontAwesomeIcon icon={faFolderOpen} />
                                            <div className='diagnostic-info-title'>No data found</div>
                                            {
                                                toast.update(toastClrDTC, {
                                                    render: 'Cleared DTC error successfully',
                                                    type: 'success',
                                                    isLoading: false,
                                                    autoClose: 2000,
                                                })
                                            }
                                        </div>
                                ))
                            }
                        </div>
                        <div className='diagnostic-button-container'>
                            {
                                (diagInfos.diagVehicle === '') ? (<></>) :
                                (
                                    (diagInfos.isDiagRun === false) ?
                                    (
                                        <>
                                        <Button onClick={() => triggerDiag(diagInfos.diagVehicle)}
                                            color   = 'green'
                                            icon    = {faMagnifyingGlass}
                                            title   = 'Start diag'
                                        />
                                        <Button onClick={() => triggerClearDiag(diagInfos.diagVehicle)}
                                            color   = 'green'
                                            icon    = {faRecycle}
                                            title   = 'Clear DTC'
                                        />
                                        <Button onClick={triggerSendReport}
                                            color   = 'orange'
                                            icon    = {faEnvelopeOpenText}
                                            title   = 'Send report'
                                        />
                                        <Button onClick={() => triggerRealtimeReading(diagInfos.diagVehicle)}
                                            color   = 'yellow'
                                            icon    = {faWrench}
                                            title   = 'Advance tool'
                                        />
                                        </>
                                    ) :
                                    (
                                        <Button onClick={() => triggerStopDiag(diagInfos.diagVehicle)}
                                            color   = 'orange'
                                            icon    = {faStop}
                                            title   = 'Stop diagnostic'
                                        />
                                    )
                                )
                            }
                        </div>
                    </div>
                    <Popup
                        open={openAdvanceTool}
                        closeOnDocumentClick
                        onClose={() => {
                            setRealtimeData({
                                engineSpeed:    0,
                                vehicleSpeed:   0,
                                powerSupply:    0,
                            })
                            setOpenAdvanceTool(false);
                            client.unsubscribe('car:actual-value');
                            client.unsubscribe('car:actuator');
                            toast.info('Disconnected to device');
                        }}
                    >
                        <AdvanceToolPopup data={ realtimeData } vehicleID={diagVehicleMqtt}/>
                    </Popup>
                </div>
            </div>
        </>
    );
}

export default Diagnostics;
