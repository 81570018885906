import React from 'react';
import { faTableCellsLarge, faRoute, faMagnifyingGlass, faGear, faFileCode, faRightFromBracket, faUserNinja } 
    from '@fortawesome/free-solid-svg-icons';

import { NavbarTopItem, NavbarMidItem, NavbarBotItem } from './NavbarItem';
import './Navbar.css';
import CarspyLogo from '../../images/car-spy-logo.svg';

function Navbar() {
    const topNavContent = {
        title: 'Car Spy Team',
        logo: CarspyLogo,
        path: '/dashboard',
        avatar: faUserNinja
    };

    const midNavContent = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            icon: faTableCellsLarge
        },
        {
            title: 'Trips Log',
            path: '/tripslog',
            icon: faRoute
        },
        {
            title: 'Diagnostics',
            path: '/diagnostics',
            icon: faMagnifyingGlass
        },
        {
            title: 'Firmware',
            path: '/firmware',
            icon: faFileCode
        },
        {
            title: 'Setting',
            path: '/setting',
            icon: faGear
        }
    ];

    const botNavContent = {
        title: 'Logout',
        path: '/sign-in',
        icon: faRightFromBracket
    }

    return (
        <>
            <div className='navbar__top-container'>
                <NavbarTopItem
                    topNavContent           = { topNavContent }
                />
            </div>
            <div className='navbar__mid-container'>
                {
                    midNavContent.map((item, index) => (
                        <NavbarMidItem
                            key             = {index}
                            midNavContent   = {item}
                        />
                    ))
                }
            </div>
            <div className='navbar__bot-container'>
                <NavbarBotItem 
                    botNavContent           = { botNavContent }
                />
            </div>
        </>
    )
}

function NavbarAdmin() {
    const topNavContent = {
        title: 'Master Admin',
        logo: CarspyLogo,
        path: '/dashboard',
        avatar: faUserNinja
    };

    const midNavContent = [
        {
            title: 'Firmware',
            path: '/admin/firmware',
            icon: faFileCode
        },
        {
            title: 'Setting',
            path: '/admin/setting',
            icon: faGear
        }
    ];

    const botNavContent = {
        title: 'Logout',
        path: '/sign-in',
        icon: faRightFromBracket
    }

    return (
        <>
            <div className='navbar__top-container'>
                <NavbarTopItem
                    topNavContent           = { topNavContent }
                />
            </div>
            <div className='navbar__mid-container'>
                {
                    midNavContent.map((item, index) => (
                        <NavbarMidItem
                            key             = {index}
                            midNavContent   = {item}
                        />
                    ))
                }
            </div>
            <div className='navbar__bot-container'>
                <NavbarBotItem 
                    botNavContent           = { botNavContent }
                />
            </div>
        </>
    )
}

export { Navbar, NavbarAdmin };
