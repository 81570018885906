import { Navigate, Outlet }     from "react-router-dom";

import { useAuth }              from "../../services/auth.service";
import { Navbar, NavbarAdmin}   from "../navbar/Navbar";

import './layouts.css';

export const UserProtectedLayout = () => {
    const { token } = useAuth();

    if (!token)
    {
        return <Navigate to="/sign-in" />;
    }

    if (JSON.parse(token).roles === 'ROLE_USER')
    {
        return (
            <>
                <div className='sidebar-container'>
                    <Navbar />
                </div>
                <div className='content-container'>
                    <Outlet />
                </div>
            </>
        );
    }

    return (
        <>
            <Navigate to='/admin/firmware' replace />
            <div className='sidebar-container'>
                <NavbarAdmin />
            </div>
            <div className='content-container'>
                <Outlet />
            </div>
        </>
    );
};

export default UserProtectedLayout;
