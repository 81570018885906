import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
    MapContainer,
    TileLayer,
    Marker,
    Tooltip,
} from 'react-leaflet'

import { Icon } from 'leaflet';

import { HeaderBar } from '../../components/headerbar/Headerbar';
import { DiagnosticCar } from '../../components/diagnostic/DiagnosticItem';
import { useAuth } from '../../services/auth.service';

import hondaCityImg from '../../images/honda-city.jpg';
import './style_dashboard.css';

let vehiclesData;
const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'};
const sportCarIcon = new Icon({
    iconUrl: '/sport-car.png',
    iconSize: [60, 60]
})

function processCommonData(rawData)
{
    let numDTCs = 0;

    numDTCs += rawData.Airbag.length;
    numDTCs += rawData.BCM.length;
    numDTCs += rawData.CGW.length;
    numDTCs += rawData.Engine.length;

    return numDTCs;
}

function Dashboard(props) {
    const { token }                 = useAuth();

    const [vehicles, setVehicles]   = useState([]);
    const [generalInfo, setGenInfo] = useState({
        vehID: ''
    });

    const fetchDTCs     = async (vehicleID) => {
        const data      = await fetch(`/api/car/` + vehicleID + `/dtc`);
        const dtcData   = await data.json();

        let lastUpdated = new Date(dtcData.DTCErrors.date);

        const numDTCs = processCommonData(dtcData.DTCErrors);
        
        setGenInfo({
            vehID: vehicleID,
            vin: (dtcData.vin === null) ? '' : dtcData.vin,
            licensePlate: (dtcData.licensePlate === null) ? '' : dtcData.licensePlate,
            modelName: dtcData.modelName,
            numDTCs: numDTCs,
            lastCheck: lastUpdated.toLocaleString("en-US", dateOptions),
            connectionStatus: dtcData.connectionStatus
        })
    }

    const fetchVehicles = async () => {
        const data      = await fetch('/api/car/user/' + JSON.parse(token).id);
        const vehData   = await data.json();

        setVehicles(vehData);
    }

    vehiclesData = (vehicles.length === 0) ? undefined : (vehicles.car.length === 0) ? undefined : vehicles.car;

    useEffect(() => {
        fetchVehicles();
    }, []);

    return (
        <>
            <HeaderBar headerBarContent="Dashboard"/>
            <div className='cover_all_single_page'>
                <div id="cover_body_content">
                    <MapContainer center={[10.8020047, 106.6388055]} zoom={13} scrollWheelZoom={true}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {
                            (vehiclesData !== undefined) ? 
                            (vehiclesData.map((item, index) => (
                                <Marker
                                    key             = {index}
                                    position        = {(item.carID.trip.length === 0)?([10.802257800977042, 106.63967676833752]):(item.carID.trip[0].location.coordinates)}
                                    icon            = {sportCarIcon}
                                >
                                    <Tooltip direction="bottom" opacity={1} permanent>{ item.carID.licensePlate }</Tooltip>
                                </Marker>
                            ))):
                            (<></>)
                        }
                    </MapContainer>
                </div>
                <div id="cover_footer_content">
                    <div className="cover_footer_col_left">
                        <div className="title_footer_left_page">Cars Information</div>
                        <div className="cover_child_page">
                            {
                                (vehiclesData !== undefined) ? 
                                (vehiclesData.map((item, index) => 
                                    <div key = {index} onClick={() => {
                                        fetchDTCs(item.carID._id);
                                    }}>
                                        <DiagnosticCar CarItemContent={ item.carID } isActive = { generalInfo.vehID === item.carID._id ? true : false } />
                                    </div>
                                )) :
                                (
                                    <div className='diagnostic-info-container'>
                                        <FontAwesomeIcon icon={faFolderOpen} />
                                        <div className='diagnostic-info-title'>No data found</div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="cover_footer_col_right" >
                        {
                            (generalInfo.vehID !== '') ?
                            (<>
                                <div className="title_footer_right_page">Current Status</div>
                                <div className='cover-footer-right-page-content'>
                                    <div className='car-image-container'>
                                        <img className='car-image' src={ hondaCityImg } alt='honda-city' />
                                    </div>
                                    <div className="w3-row child_right_page">
                                        <div className="first_info_child_page">{generalInfo.licensePlate}</div>
                                        <div className="second_info_child_page">{generalInfo.modelName}</div>
                                        <div className="third_info_child_page">{generalInfo.vin}</div>
                                    </div>
                                    <div className="basic_info">
                                        <div className='basic-info-left'>
                                            <div className='basic-info-container'>
                                                <div className='basic-info-content-container'>
                                                    <div className='basic-info-title'>DTC number</div>
                                                    <div className='basic-info-content'>{generalInfo.numDTCs}</div>
                                                </div>
                                                <div className='basic-info-icon'>
                                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>) :
                            (
                                <></>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;