import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { faUserNinja, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from '../../services/auth.service';

import carspyLogo from '../../images/carspy-logo.jpg';

import './signin.css';

function SignInPage(props) {
    const { login }  = useAuth();

    const formRef       = useRef(null);
    const username      = useRef(null);
    const password      = useRef(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        let data = new FormData();

        data.append('username', username.current.value);
        data.append('password', password.current.value);

        const response  = await fetch ('/api/auth/signin',
            {
                method: 'POST',
                body: data
            }
        );

        const result    = await response.json();

        if (result.status === 'error')
        {
            toast.error(result.message);
        }
        else 
        {
            login(JSON.stringify(result));
        }
    }

    return (
        <div className='signin-page-container'>
            <img className='signin-image-background' src={ carspyLogo } alt='carspy logo' />
            <div className='signin-form-container'>
                <img className='signin-icon-container' src={ carspyLogo } alt='carspy logo' />
                <div className='signin-title'>Carspy diagnostic</div>
                <div className='signin-body'>
                    <form ref={formRef} className='signin-form'>
                        <div className='signin-form-input'>
                            <div className='signin-input-icon'>
                                <FontAwesomeIcon icon={faUserNinja} />
                            </div>
                            <input 
                                type        = 'text' 
                                id          = 'username'
                                className   = 'signin-input'
                                placeholder = 'Username'
                                ref         = {username}
                            />
                        </div>
                        <div className='signin-form-input'>
                            <div className='signin-input-icon'>
                                <FontAwesomeIcon icon={faLock} />
                            </div>
                            <input 
                                type        = 'password' 
                                id          = 'password'
                                className   = 'signin-input'
                                placeholder = 'Password'
                                ref         = {password}
                            />
                        </div>
                    </form>
                    <button className='signin-button' onClick={handleLogin}>Login</button>
                </div>
            </div>
        </div>
    )
}

export default SignInPage;
