let mqtt        = require('mqtt/dist/mqtt');
const options   = {
    username: process.env.REACT_APP_MQTT_USERNAME
}

const client = mqtt.connect(`ws://${process.env.REACT_APP_SERVER}:${process.env.REACT_APP_PORT}`, options)

client.on('error', function (err) {
    console.log(err)
    client.end()
})
  
client.on('connect', function () {
    client.subscribe('car:diag', { qos: 0 })
})

export default client;
