import React from "react";
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAuth } from '../../services/auth.service';

import './Navbar.css';

function NavbarTopItem(props) {
    const { token }         = useAuth();
    const { topNavContent } = props;

    return (
        <>
            <div className='navbar__top-title'>
                { topNavContent.title }
            </div>
            <div className='navbar__top-logo'>
                <Link to={ topNavContent.path }>
                    <img className='brand-logo' src={ topNavContent.logo } alt='brand-logo'/>
                </Link>
            </div>
            <div className='navbar__top-user'>
                <FontAwesomeIcon icon={ topNavContent.avatar } />
                <div className='username-title'>Hello {(JSON.parse(token)).firstname}</div>
            </div>
        </>
    )
}

function NavbarMidItem(props) {
    const { midNavContent } = props;

    const navLinkClass = ({ isActive }) => {
        return isActive ? 'nav-link activated' : 'nav-link';
    }

    return (
        <NavLink
        className           = { navLinkClass }
        to                  = { midNavContent.path }
        >
            <div className='navbar__mid-icon'>
                <FontAwesomeIcon icon={ midNavContent.icon } />
            </div>
            <div className='navbar__mid-title'>
                { midNavContent.title }
            </div>
        </NavLink>
    )
}

function NavbarBotItem(props) {
    const { logout }        = useAuth();
    const { botNavContent } = props;

    const handleLogout = () => {
        logout();
    }

    return (
        <NavLink
            to              = { botNavContent.path }
            className       = 'nav-link'
            onClick         = {handleLogout}
        >
            <div className='navbar__bot-icon'>
                <FontAwesomeIcon icon={ botNavContent.icon } />
            </div>
            <div className='navbar__bot-title'>
                { botNavContent.title }
            </div>
        </NavLink>
    )
}

export { NavbarTopItem, NavbarMidItem, NavbarBotItem };
