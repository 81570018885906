import React, { useState, useRef, useEffect } from 'react';
import Popup from 'reactjs-popup';

import { toast } from 'react-toastify';
import { faFile, faCubes, faFileCirclePlus, faFileArrowUp, faFileLines, faCircleInfo, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../components/button/Button';
import { useAuth } from '../../services/auth.service';

import './fw_popup.css'

import client from '../../setupMQTT';

import vehIcon from '../../images/sport-car.png';
import engineIcon from '../../images/car-engine.png';
import batteryIcon from '../../images/car-battery.png';

function UploadFWPopup(props)
{
    const [fileList, setFileList]   = useState(FileList | null);
    const [openModal, setOpenModal] = useState(false);

    const formRef                   = useRef(null);
    const moduleFirmware            = useRef(null);
    const majorVersion              = useRef(null);
    const minorVersion              = useRef(null);
    const patchVersion              = useRef(null);
    const description               = useRef(null);

    let files = fileList ? [...fileList] : [];

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };

    const handleUpload = () => {
        if (!fileList)
        {
            toast.error("No files selected");
            return;
        }

        if (fileList.length !== 2)
        {
            toast.error("Missing sequence or hex files");
            return;
        }

        const data = new FormData();

        if (files[0].type === "application/json")
        {
            data.append('sequenceFile', files[0], files[0].name);
            data.append('firmwareFile', files[1], files[1].name);
        } 
        else
        {
            data.append('firmwareFile', files[0], files[0].name);
            data.append('sequenceFile', files[1], files[1].name);
        }

        data.append('moduleFirmware', moduleFirmware.current.value);
        data.append('majorVersion', majorVersion.current.value);
        data.append('minorVersion', minorVersion.current.value);
        data.append('patchVersion', patchVersion.current.value);
        data.append('description', description.current.value)

        const toastId = toast.loading("Uploading...")

        fetch('/api/firmware', {
            method: 'POST',
            body:   data,
        })
        .then((respose) => {
            respose.json()
            .then((result) => {
                if (result.status === 'success')
                {
                    setFileList(null);
                    formRef.current.reset();
                    setOpenModal(false);
                    toast.update(toastId, {
                        render: result.message,
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
                else
                {
                    toast.update(toastId, {
                        render: result.message,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
            })
        })
        .catch((respose) => {
            respose.json()
            .then((result) => {
                toast.update(toastId, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                })
            })
        })
    }

    return (
        <>
            <Popup 
                open={openModal}
                closeOnDocumentClick 
                onClose={() => setOpenModal(false)}
            >
            <div className='popup-container'>
                <div className='popup-header'>
                    <div className='popup-header-icon'>
                        <FontAwesomeIcon icon={faCubes} />
                    </div>
                    <div className='popup-header-title'>
                        Creating new firmware
                    </div>
                </div>
                <form ref={formRef} className='popup-center'>
                    <div className='popup-3-content-column'>
                        <div className='popup-small-content'>
                            <label 
                                htmlFor     = 'major-version'
                                className   = 'form-label'
                            >Major version*</label>
                            <input 
                                type        = 'number' 
                                id          = 'major-version'
                                className   = 'form-input'
                                ref         = {majorVersion}
                            />
                        </div>
                        <div className='popup-small-content'>
                            <label 
                                htmlFor     = 'minor-version'
                                className   = 'form-label'
                            >Minor version*</label>
                            <input 
                                type        = 'number' 
                                id          = 'minor-version'
                                className   = 'form-input'
                                ref         = {minorVersion}
                            />
                        </div>
                        <div className='popup-small-content'>
                            <label 
                                htmlFor     = 'patch-version'
                                className   = 'form-label'
                            >Patch Version*</label>
                            <input 
                                type        = 'number' 
                                id          = 'patch-version'
                                className   = 'form-input'
                                ref         = {patchVersion}
                            />
                        </div>
                    </div>
                    <div className='popup-3-content-column'>
                        <div className='popup-small-content'>
                            <label 
                                htmlFor     = 'module-firmware'
                                className   = 'form-label'
                            >Module*</label>
                            <select
                                id          ='module-firmware'
                                name        ='module'
                                className   ='form-select'
                                ref         ={moduleFirmware}
                            >
                                <option value=''>Please select module</option>
                                <option value='light'>Light</option>
                                <option value='engine'>Engine</option>
                                <option value='bcm'>BCM</option>
                                <option value='cgw'>CGW</option>
                            </select>
                        </div>
                        <div className='popup-small-content'>
                            <label 
                                htmlFor     = 'description'
                                className   = 'form-label'
                            >Description</label>
                            <textarea
                                type        = 'text' 
                                id          = 'description'
                                className   = 'form-large-input'
                                ref         = {description}
                                maxLength  = {300}
                            />
                        </div>
                        <div className='popup-medium-content'>
                            {
                                files.map((file, i) =>
                                    <div key={i} className='popup-file-container'>
                                        <FontAwesomeIcon icon={faFileLines} />
                                        <div className='popup-file'>{file.name}</div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </form>
                <div className='popup-footer'>
                    <label
                        htmlFor='files'
                        className='button button-yellow'
                    >
                        <div className='button-icon'>
                            <FontAwesomeIcon icon={faFileCirclePlus} />
                        </div>
                        <div className='button-title'>Choose files</div>
                    </label>
                    <input
                        id          = 'files'
                        type        = 'file'
                        className   = 'form-file-input'
                        onChange    = {handleFileChange}
                        hidden
                        multiple
                    />
                    <Button
                        color   = 'orange'
                        icon    = {faFileArrowUp}
                        title   = 'Upload'
                        onClick = {handleUpload}
                    />
                </div>
            </div>
        </Popup>
        <Button
            color   = 'green'
            icon    = {faFile}
            title   = 'New Firmware'
            onClick = {() => setOpenModal(o => !o)}
        />
        </>
    );
}

function InfoFWPopup(props)
{
    const description = props.description

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCircleInfo} />
                </div>
                <div className='popup-header-title'>
                    Firmware Information
                </div>
            </div>
            <div>
                <div className='popup-small-content'>
                    <label 
                        htmlFor     = 'description'
                        className   = 'form-label'
                    >Description</label>
                    <textarea
                        id          = 'description'
                        readOnly    = {true}
                        className   = 'form-large-input'
                        value       = {description}
                        maxLength   = {300}
                    />
                </div>
            </div>
        </div>
    )
}

function UpdateFWPopup(props)
{
    const FWData        = props.data;

    const formRef       = useRef(null);
    const majorVersion  = useRef(null);
    const minorVersion  = useRef(null);
    const patchVersion  = useRef(null);
    const description   = useRef(null);

    const handleUpload = async () => {
        const data = new FormData();

        data.append('majorVersion', majorVersion.current.value);
        data.append('minorVersion', minorVersion.current.value);
        data.append('patchVersion', patchVersion.current.value);
        data.append('description', description.current.value)

        const respose = await fetch(`/api/firmware/${FWData._id}`, {
            method: 'POST',
            body:   data,
        });

        const result = await respose.json();

        if (result.status === 'error')
        {
            toast.error(result.message);
        }
        else 
        {
            toast.success(result.message);
            formRef.current.reset();
            window.location.reload();
        }
    }

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCubes} />
                </div>
                <div className='popup-header-title'>
                    Update Firmware Infomation
                </div>
            </div>
            <form ref={formRef} className='popup-center'>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'major-version'
                            className   = 'form-label'
                        >Major version*</label>
                        <input 
                            type        = 'number' 
                            id          = 'major-version'
                            className   = 'form-input'
                            defaultValue= {FWData.majorVersion}
                            ref         = {majorVersion}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'minor-version'
                            className   = 'form-label'
                        >Minor version*</label>
                        <input 
                            type        = 'number' 
                            id          = 'minor-version'
                            className   = 'form-input'
                            defaultValue= {FWData.minorVersion}
                            ref         = {minorVersion}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'patch-version'
                            className   = 'form-label'
                        >Patch Version*</label>
                        <input 
                            type        = 'number' 
                            id          = 'patch-version'
                            className   = 'form-input'
                            defaultValue= {FWData.patchVersion}
                            ref         = {patchVersion}
                        />
                    </div>
                </div>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'description'
                            className   = 'form-label'
                        >Description</label>
                        <textarea
                            type        = 'text' 
                            id          = 'description'
                            className   = 'form-large-input'
                            defaultValue= {FWData.description}
                            ref         = {description}
                            maxLength  = {300}
                        />
                    </div>
                </div>
            </form>
            <div className='popup-footer'>
                <Button
                    color   = 'orange'
                    icon    = {faFileArrowUp}
                    title   = 'Update'
                    onClick = {handleUpload}
                />
            </div>
        </div>
    )
}

function AdvanceToolPopup(props)
{
    const data      = props.data;
    const vehicleID = props.vehicleID;

    const [controlCmd, setControlCmd] = useState({
        leftSignal:     0,
        rightSignal:    0,
        hazardSignal:   0,
        turnOffSignal:  0,
        lockDoor:       0,
        unlockDoor:     0,
    })

    const handleSendCommand = (command) => {
        toast.info('Sending command to device...');

        client.publish(vehicleID, command, { qos: 0, retain: false });
    }

    useEffect(() => {
        client.on('message', function (topic, message, packet) {
            if (topic === 'car:actuator')
            {
                let rawData = message.toString().split(':');

                if (vehicleID === rawData[0])
                {
                    if ('OK' === rawData[2])
                    {
                        toast.success('Device responses OK');

                        if ('LeftSignal' === rawData[1])
                        {
                            setControlCmd({...controlCmd, leftSignal: 1});
                        }
                        else if ('RightSignal' === rawData[1])
                        {
                            setControlCmd({...controlCmd, rightSignal: 1});
                        }
                        else if ('HazardSignal' === rawData[1])
                        {
                            setControlCmd({...controlCmd, hazardSignal: 1});
                        }
                        else if ('TurnOffSignal' === rawData[1])
                        {
                            setControlCmd({...controlCmd, leftSignal: 0, rightSignal: 0, hazardSignal: 0, turnOffSignal: 0});
                        }
                        else if ('LockDoor' === rawData[1])
                        {
                            setControlCmd({...controlCmd, lockDoor: 1});
                        }
                        else if ('UnlockDoor' === rawData[1])
                        {
                            setControlCmd({...controlCmd, unlockDoor: 1});
                        }
                    }
                    else
                    {
                        toast.error('Device responses not OK');

                        if ('LeftSignal' === rawData[1])
                        {
                            setControlCmd({...controlCmd, leftSignal: 2});
                        }
                        else if ('RightSignal' === rawData[1])
                        {
                            setControlCmd({...controlCmd, rightSignal: 2});
                        }
                        else if ('HazardSignal' === rawData[1])
                        {
                            setControlCmd({...controlCmd, hazardSignal: 2});
                        }
                        else if ('TurnOffSignal' === rawData[1])
                        {
                            
                            setControlCmd({...controlCmd, turnOffSignal: 2});
                        }
                        else if ('LockDoor' === rawData[1])
                        {
                            setControlCmd({...controlCmd, lockDoor: 2});
                        }
                        else if ('UnlockDoor' === rawData[1])
                        {
                            setControlCmd({...controlCmd, unlockDoor: 2});
                        }
                    }
                }
            }
        })
    }, [])

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCubes} />
                </div>
                <div className='popup-header-title'>
                    Advance tool
                </div>
            </div>
            <div className='popup-center-tool'>
                <div className='popup-tag-container'>
                    <div className='popup-tag-component-container'>
                        <div className='tag-component-title'>Engine speed (rpm)</div>
                        <div className='tag-component-content-container'>
                            <div className='tag-component-content-title'>{ data.engineSpeed }</div>
                            <img className='tag-component-content-icon' src={ engineIcon } alt='module icon'/>
                        </div>
                    </div>
                    <div className='popup-tag-component-container'>
                        <div className='tag-component-title'>Vehicle speed (km/h)</div>
                        <div className='tag-component-content-container'>
                            <div className='tag-component-content-title'>{ data.vehicleSpeed }</div>
                            <img className='tag-component-content-icon' src={ vehIcon } alt='module icon'/>
                        </div>
                    </div>
                    <div className='popup-tag-component-container'>
                        <div className='tag-component-title'>Power supply (volt)</div>
                        <div className='tag-component-content-container'>
                            <div className='tag-component-content-title'>{ data.powerSupply }</div>
                            <img className='tag-component-content-icon' src={ batteryIcon } alt='module icon'/>
                        </div>
                    </div>
                </div>
                <div className='popup-control-container'>
                    <div className='popup-row'>
                        <div
                            className={(controlCmd.leftSignal === 1)?'popup-control-active popup-button':((controlCmd.leftSignal === 2)?'popup-control-failed popup-button':'popup-button')}
                            onClick={() => {handleSendCommand('LeftSignal')}}>Left signal</div>
                        <div 
                            className={(controlCmd.rightSignal === 1)?'popup-control-active popup-button':((controlCmd.rightSignal === 2)?'popup-control-failed popup-button':'popup-button')}
                            onClick={() => {handleSendCommand('RightSignal')}}>Right signal</div>
                    </div>
                    <div className='popup-row'>
                        <div 
                            className={(controlCmd.hazardSignal === 1)?'popup-control-active popup-button':((controlCmd.hazardSignal === 2)?'popup-control-failed popup-button':'popup-button')}
                            onClick={() => {handleSendCommand('HazardSignal')}}>Hazard signal</div>
                        <div 
                            className={(controlCmd.turnOffSignal === 1)?'popup-control-active popup-button':((controlCmd.turnOffSignal === 2)?'popup-control-failed popup-button':'popup-button')}
                            onClick={() => {handleSendCommand('TurnOffSignal')}}>Turn off signal</div>
                    </div>
                    <div className='popup-row'>
                        <div 
                            className={(controlCmd.lockDoor === 1)?'popup-control-active popup-button':((controlCmd.lockDoor === 2)?'popup-control-failed popup-button':'popup-button')}
                            onClick={() => {handleSendCommand('LockDoor')}}>Lock door</div>
                        <div 
                            className={(controlCmd.unlockDoor === 1)?'popup-control-active popup-button':((controlCmd.unlockDoor === 2)?'popup-control-failed popup-button':'popup-button')}
                            onClick={() => {handleSendCommand('UnlockDoor')}}>Unlock door</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function UpdateDTCPopup(props)
{
    const DTCData       = props.data;

    const formRef       = useRef(null);
    const DTCcode       = useRef(null);
    const description   = useRef(null);
    const severity      = useRef(null);

    const handleUpdate = async () => {
        const toastId = toast.loading("Uploading...");
        const data = new FormData();

        data.append('DTCcode', DTCcode.current.value);
        data.append('description', description.current.value);
        data.append('severity', severity.current.value);

        const respose = await fetch(`/api/dtc/${DTCData._id}`, {
            method: 'POST',
            body:   data,
        });

        const result = await respose.json();

        if (result.status === 'error')
        {
            toast.update(toastId, {
                render: result.message,
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            })
        }
        else 
        {
            toast.update(toastId, {
                render: result.message,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
            })
            formRef.current.reset();
            // window.location.reload();
        }
    }

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCubes} />
                </div>
                <div className='popup-header-title'>
                    Update DTC Infomation
                </div>
            </div>
            <form ref={formRef} className='popup-center'>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'dtc-code'
                            className   = 'form-label'
                        >DTCcode*</label>
                        <input 
                            type        = 'text' 
                            id          = 'dtc-code'
                            className   = 'form-input'
                            defaultValue= {DTCData.DTCcode}
                            ref         = {DTCcode}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'dtc-severity'
                            className   = 'form-label'
                        >Severity*</label>
                        <select
                            id          ='dtc-severity'
                            name        ='module'
                            className   ='form-select'
                            defaultValue= {DTCData.severity}
                            ref         = {severity}
                        >
                            <option value=''>Please select severity</option>
                            <option value='3-high'>High</option>
                            <option value='2-medium'>Medium</option>
                            <option value='1-low'>Low</option>
                        </select>
                    </div>
                </div>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'description'
                            className   = 'form-label'
                        >Description</label>
                        <textarea
                            type        = 'text' 
                            id          = 'description'
                            className   = 'form-large-input'
                            defaultValue= {DTCData.description}
                            ref         = {description}
                            maxLength   = {90}
                        />
                    </div>
                </div>
            </form>
            <div className='popup-footer'>
                <Button
                    color   = 'orange'
                    icon    = {faPen}
                    title   = 'Update'
                    onClick = {handleUpdate}
                />
            </div>
        </div>
    )
}

function NewDTCPopup(props)
{
    const formRef       = useRef(null);
    const DTCcode       = useRef(null);
    const description   = useRef(null);
    const severity      = useRef(null);

    const handleUpdate = async () => {
        const toastId = toast.loading("Creating...");
        const data = new FormData();

        data.append('DTCcode', DTCcode.current.value);
        data.append('description', description.current.value);
        data.append('severity', severity.current.value);

        const respose = await fetch(`/api/dtc`, {
            method: 'POST',
            body:   data,
        });

        const result = await respose.json();

        if (result.status === 'error')
        {
            toast.update(toastId, {
                render: result.message,
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            })
        }
        else 
        {
            toast.update(toastId, {
                render: result.message,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
            })
            formRef.current.reset();
            window.location.reload();
        }
    }

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCubes} />
                </div>
                <div className='popup-header-title'>
                    Create New DTC Infomation
                </div>
            </div>
            <form ref={formRef} className='popup-center'>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'dtc-code'
                            className   = 'form-label'
                        >DTCcode*</label>
                        <input 
                            type        = 'text' 
                            id          = 'dtc-code'
                            className   = 'form-input'
                            ref         = {DTCcode}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'dtc-severity'
                            className   = 'form-label'
                        >Severity*</label>
                        <select
                            id          ='dtc-severity'
                            name        ='module'
                            className   ='form-select'
                            ref         = {severity}
                        >
                            <option value=''>Please select severity</option>
                            <option value='3-high'>High</option>
                            <option value='2-medium'>Medium</option>
                            <option value='1-low'>Low</option>
                        </select>
                    </div>
                </div>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'description'
                            className   = 'form-label'
                        >Description</label>
                        <textarea
                            type        = 'text' 
                            id          = 'description'
                            className   = 'form-large-input'
                            ref         = {description}
                            maxLength   = {90}
                        />
                    </div>
                </div>
            </form>
            <div className='popup-footer'>
                <Button
                    color   = 'green'
                    icon    = {faFileArrowUp}
                    title   = 'Create'
                    onClick = {handleUpdate}
                />
            </div>
        </div>
    )
}

function NewDevice(props)
{
    const formRef           = useRef(null);

    const lightFWMajorVer   = useRef(0);
    const lightFWMinorVer   = useRef(0);
    const lightFWPatchVer   = useRef(0);
    const engineFWMajorVer  = useRef(0);
    const engineFWMinorVer  = useRef(0);
    const engineFWPatchVer  = useRef(0);
    const BCMFWMajorVer     = useRef(0);
    const BCMFWMinorVer     = useRef(0);
    const BCMFWPatchVer     = useRef(0);
    const CGWFWMajorVer     = useRef(0);
    const CGWFWMinorVer     = useRef(0);
    const CGWFWPatchVer     = useRef(0);

    const modelName         = useRef(null);

    const handleUpdate = async () => {
        const toastId = toast.loading("Creating...");
        const data = new FormData();

        const firmwareVersion = [
            {
                moduleFirmware: 'light',
                majorVersion: lightFWMajorVer.current.value,
                minorVersion: lightFWMinorVer.current.value,
                patchVersion: lightFWPatchVer.current.value,
            },
            {
                moduleFirmware: 'engine',
                majorVersion: engineFWMajorVer.current.value,
                minorVersion: engineFWMinorVer.current.value,
                patchVersion: engineFWPatchVer.current.value,
            },
            {
                moduleFirmware: 'bcm',
                majorVersion: BCMFWMajorVer.current.value,
                minorVersion: BCMFWMinorVer.current.value,
                patchVersion: BCMFWPatchVer.current.value,
            },
            {
                moduleFirmware: 'cgw',
                majorVersion: CGWFWMajorVer.current.value,
                minorVersion: CGWFWMinorVer.current.value,
                patchVersion: CGWFWPatchVer.current.value,
            }
        ];

        data.append('modelName', modelName.current.value);
        data.append('firmwareVersion', JSON.stringify(firmwareVersion));

        const respose = await fetch(`/api/car`, {
            method: 'POST',
            body:   data,
        });

        const result = await respose.json();

        if (result.status === 'error')
        {
            toast.update(toastId, {
                render: result.message,
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            })
        }
        else 
        {
            toast.update(toastId, {
                render: result.message,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
            })
            formRef.current.reset();
            window.location.reload();
        }
    }

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCubes} />
                </div>
                <div className='popup-header-title'>
                    Create new device
                </div>
            </div>
            <form ref={formRef} className='popup-center'>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'model-name'
                            className   = 'form-label'
                        >Model Name</label>
                        <select
                            id          ='model-name'
                            name        ='module'
                            className   ='form-select'
                            ref         = {modelName}
                        >
                            <option value=''>Please select model</option>
                            <option value='Honda City'>Honda City</option>
                        </select>
                    </div>
                    {/* <div className='popup-small-content'>
                        <label 
                            className   = 'form-label'
                        >Light FW</label>
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Major Ver'
                            ref         = {lightFWMajorVer}
                        />
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Minor Ver'
                            ref         = {lightFWMinorVer}
                        />
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Patch Ver'
                            ref         = {lightFWPatchVer}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            className   = 'form-label'
                        >Engine FW</label>
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Major Ver'
                            ref         = {engineFWMajorVer}
                        />
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Minor Ver'
                            ref         = {engineFWMinorVer}
                        />
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Patch Ver'
                            ref         = {engineFWPatchVer}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            className   = 'form-label'
                        >BCM FW</label>
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Major Ver'
                            ref         = {BCMFWMajorVer}
                        />
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Minor Ver'
                            ref         = {BCMFWMinorVer}
                        />
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Patch Ver'
                            ref         = {BCMFWPatchVer}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            className   = 'form-label'
                        >CGW FW</label>
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Major Ver'
                            ref         = {CGWFWMajorVer}
                        />
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Minor Ver'
                            ref         = {CGWFWMinorVer}
                        />
                        <input 
                            type        = 'number' 
                            className   = 'form-number-input'
                            placeholder = 'Patch Ver'
                            ref         = {CGWFWPatchVer}
                        />
                    </div> */}
                </div>
            </form>
            <div className='popup-footer'>
                <Button
                    color   = 'green'
                    icon    = {faPen}
                    title   = 'Create'
                    onClick = {handleUpdate}
                />
            </div>
        </div>
    )
}

function UpdateDevice(props)
{
    const { deviceInfos }   = props;

    const formRef           = useRef(null);

    const modelName          = useRef(null);

    const handleUpdate = async () => {
        const toastId = toast.loading("Uploading...");
        const data = new FormData();
        
        data.append('modelName', modelName.current.value);

        const respose = await fetch(`/api/car`, {
            method: 'POST',
            body:   data,
        });

        const result = await respose.json();

        if (result.status === 'error')
        {
            toast.update(toastId, {
                render: result.message,
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            })
        }
        else 
        {
            toast.update(toastId, {
                render: result.message,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
            })
            formRef.current.reset();
            window.location.reload();
        }
    }

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCubes} />
                </div>
                <div className='popup-header-title'>
                    Update device information
                </div>
            </div>
            <form ref={formRef} className='popup-center'>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'model-name'
                            className   = 'form-label'
                        >Model Name</label>
                        <select
                            id          ='model-name'
                            name        ='module'
                            className   ='form-select'
                            defaultValue= {deviceInfos.modelName}
                            ref         = {modelName}
                        >
                            <option value=''>Please select model</option>
                            <option value='Honda City'>Honda City</option>
                        </select>
                    </div>
                </div>
            </form>
            <div className='popup-footer'>
                <Button
                    color   = 'orange'
                    icon    = {faPen}
                    title   = 'Update'
                    onClick = {handleUpdate}
                />
            </div>
        </div>
    )
}

function NewUser(props)
{
    const { token } = useAuth();
    const formRef   = useRef(null);

    const username  = useRef(null);
    const password  = useRef(null);
    const firstname = useRef(null);
    const lastname  = useRef(null);
    const phone     = useRef(null);
    const email     = useRef(null);
    const address   = useRef(null);
    const roles     = useRef(null);

    const handleUpdate = async () => {
        const toastId = toast.loading("Creating...");
        const data = new FormData();

        data.append('username', username.current.value);
        data.append('password', password.current.value);
        data.append('firstname', firstname.current.value);
        data.append('lastname', lastname.current.value);
        data.append('phone', phone.current.value);
        data.append('email', email.current.value);
        data.append('address', address.current.value);
        data.append('roles', roles.current.value);
        
        const respose = await fetch(`/api/admin/signup`, {
            method: 'POST',
            headers: {
                'x-access-token': JSON.parse(token).accessToken
            },
            body:   data,
        });

        const result = await respose.json();

        if (result.status === 'error')
        {
            toast.update(toastId, {
                render: result.message,
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            })
        }
        else 
        {
            toast.update(toastId, {
                render: result.message,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
            })
            formRef.current.reset();
            window.location.reload();
        }
    }

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCubes} />
                </div>
                <div className='popup-header-title'>
                    Create New User
                </div>
            </div>
            <form ref={formRef} className='popup-center'>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'username'
                            className   = 'form-label'
                        >Username*</label>
                        <input 
                            type        = 'text' 
                            id          = 'username'
                            className   = 'form-input'
                            ref         = {username}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'password'
                            className   = 'form-label'
                        >Password*</label>
                        <input 
                            type        = 'password' 
                            id          = 'password'
                            className   = 'form-input'
                            ref         = {password}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'firstname'
                            className   = 'form-label'
                        >First name*</label>
                        <input 
                            type        = 'text' 
                            id          = 'firstname'
                            className   = 'form-input'
                            ref         = {firstname}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'lastname'
                            className   = 'form-label'
                        >Last name*</label>
                        <input 
                            type        = 'text' 
                            id          = 'lastname'
                            className   = 'form-input'
                            ref         = {lastname}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'phone'
                            className   = 'form-label'
                        >Phone*</label>
                        <input 
                            type        = 'text' 
                            id          = 'phone'
                            className   = 'form-input'
                            ref         = {phone}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'email'
                            className   = 'form-label'
                        >Email*</label>
                        <input 
                            type        = 'text' 
                            id          = 'email'
                            className   = 'form-input'
                            ref         = {email}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'address'
                            className   = 'form-label'
                        >Address*</label>
                        <input 
                            type        = 'text' 
                            id          = 'address'
                            className   = 'form-input'
                            ref         = {address}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'roles'
                            className   = 'form-label'
                        >Role*</label>
                        <select
                            id          ='roles'
                            className   ='form-select'
                            ref         = {roles}
                        >
                            <option value=''>Please select role</option>
                            <option value='user'>User</option>
                            <option value='admin'>Admin</option>
                        </select>
                    </div>
                </div>
            </form>
            <div className='popup-footer'>
                <Button
                    color   = 'green'
                    icon    = {faFileArrowUp}
                    title   = 'Create'
                    onClick = {handleUpdate}
                />
            </div>
        </div>
    )
}

function NewUserDevicePopup(props)
{
    const { token } = useAuth();
    const formRef   = useRef(null);

    const device        = useRef(null);
    const licensePlate  = useRef(null);
    const vin           = useRef(null);

    const handleUpdate = async () => {
        const toastId   = toast.loading("Creating...");
        const data = new FormData();

        const deviceID  = device.current.value;
        const userID    = JSON.parse(token).id;
        
        data.append('licensePlate', licensePlate.current.value);
        data.append('vin', vin.current.value);

        const respose = await fetch(`/api/car/${deviceID}/user/${userID}`, {
            method: 'POST',
            headers: {
                'x-access-token': JSON.parse(token).accessToken
            },
            body: data
        });

        const result = await respose.json();

        if (result.status === 'error')
        {
            toast.update(toastId, {
                render: result.message,
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            })
        }
        else 
        {
            toast.update(toastId, {
                render: result.message,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
            })
            formRef.current.reset();
            window.location.reload();
        }
    }

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCubes} />
                </div>
                <div className='popup-header-title'>
                    Add New Device
                </div>
            </div>
            <form ref={formRef} className='popup-center'>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'deviceID'
                            className   = 'form-label'
                        >Device id*</label>
                        <input 
                            type        = 'text' 
                            id          = 'deviceID'
                            className   = 'form-input'
                            ref         = {device}
                            />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'vin'
                            className   = 'form-label'
                        >VIN*</label>
                        <input 
                            type        = 'text' 
                            id          = 'vin'
                            className   = 'form-input'
                            ref         = {vin}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'licensePlate'
                            className   = 'form-label'
                        >License Plate*</label>
                        <input 
                            type        = 'text' 
                            id          = 'licensePlate'
                            className   = 'form-input'
                            ref         = {licensePlate}
                        />
                    </div>
                </div>
            </form>
            <div className='popup-footer'>
                <Button
                    color   = 'green'
                    icon    = {faFileArrowUp}
                    title   = 'Add device'
                    onClick = {handleUpdate}
                />
            </div>
        </div>
    )
}

function UpdateUserDevicePopup(props)
{
    const DeviceData    = props.data;

    const { token } = useAuth();
    const formRef   = useRef(null);

    const licensePlate  = useRef(null);
    const vin           = useRef(null);

    const handleUpdate = async () => {
        const toastId   = toast.loading("Updating...");
        const data = new FormData();

        const deviceID  = DeviceData._id;
        
        data.append('licensePlate', licensePlate.current.value);
        data.append('vin', vin.current.value);

        const respose = await fetch(`/api/car/${deviceID}`, {
            method: 'POST',
            headers: {
                'x-access-token': JSON.parse(token).accessToken
            },
            body: data
        });

        const result = await respose.json();

        if (result.status === 'error')
        {
            toast.update(toastId, {
                render: result.message,
                type: 'error',
                isLoading: false,
                autoClose: 2000,
            })
        }
        else 
        {
            toast.update(toastId, {
                render: result.message,
                type: 'success',
                isLoading: false,
                autoClose: 2000,
            })
            formRef.current.reset();
            window.location.reload();
        }
    }

    return (
        <div className='popup-container'>
            <div className='popup-header'>
                <div className='popup-header-icon'>
                    <FontAwesomeIcon icon={faCubes} />
                </div>
                <div className='popup-header-title'>
                    Edit Device Information
                </div>
            </div>
            <form ref={formRef} className='popup-center'>
                <div className='popup-3-content-column'>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'vin'
                            className   = 'form-label'
                        >VIN*</label>
                        <input 
                            type        = 'text' 
                            id          = 'vin'
                            className   = 'form-input'
                            defaultValue= {DeviceData.vin}
                            ref         = {vin}
                        />
                    </div>
                    <div className='popup-small-content'>
                        <label 
                            htmlFor     = 'licensePlate'
                            className   = 'form-label'
                        >License Plate*</label>
                        <input 
                            type        = 'text' 
                            id          = 'licensePlate'
                            className   = 'form-input'
                            defaultValue= {DeviceData.licensePlate}
                            ref         = {licensePlate}
                        />
                    </div>
                </div>
            </form>
            <div className='popup-footer'>
                <Button
                    color   = 'orange'
                    icon    = {faPen}
                    title   = 'Update'
                    onClick = {handleUpdate}
                />
            </div>
        </div>
    )
}

export { UploadFWPopup, InfoFWPopup, UpdateFWPopup, AdvanceToolPopup, UpdateDTCPopup, NewDTCPopup,
        NewDevice, UpdateDevice, NewUser, NewUserDevicePopup, UpdateUserDevicePopup };
