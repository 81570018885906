import React        from 'react';
import { 
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements
} from "react-router-dom";

import Dashboard            from '../pages/dashboard/dashboard';
import Triplogs             from '../pages/triplogs/triplogs';
import Diagnostics          from '../pages/diagnostics/diagnostics';
import Firmware             from '../pages/firmware/firmware';
import Setting              from '../pages/setting/setting';
import SignInPage           from '../pages/signin/signin';
import SettingUser          from '../pages/setting/settingUser.page';

import AuthLayout from '../components/layouts/auth.component';
import HomeLayout from '../components/layouts/home.component';
import UserProtectedLayout from '../components/layouts/user.component';
import AdminProtectedLayout from '../components/layouts/admin.component';

const WebRoutes = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                element     = { <AuthLayout /> }
            >
                <Route path = '/sign-in'            element = {<HomeLayout />}>
                    <Route path= '/sign-in'         element = {<SignInPage />} />
                </Route>
                <Route path='/'                     element = {<UserProtectedLayout />}>
                    <Route path = '/dashboard'      element = {<Dashboard />} />
                    <Route path = '/tripslog'       element = {<Triplogs />} />
                    <Route path = '/diagnostics'    element = {<Diagnostics />} />
                    <Route path = '/firmware'       element = {<Firmware />} />
                    <Route path = '/setting'        element = {<SettingUser />} />
                </Route>
                <Route path='/'                     element = {<AdminProtectedLayout />}>
                    <Route path = '/admin/firmware'       element = {<Firmware />} />
                    <Route path = '/admin/setting'        element = {<Setting />} />
                </Route>
            </Route>
        )
    )

    return (<RouterProvider router={router} />);
};

export default WebRoutes;
