import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faExclamation, faFolderOpen, faLink, faLinkSlash, faPen, 
    faPlus, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../button/Button';
import { useAuth } from '../../services/auth.service';
import { NewDTCPopup, NewDevice, NewUser, NewUserDevicePopup, UpdateDTCPopup, UpdateDevice, UpdateUserDevicePopup } from '../../components/popup/fw_popup';

import './setting.component.css'

function processDTCData(rawData)
{
    let processedData   = [];
    let idx             = 0;
    
    if (rawData.length)
    {
        rawData.forEach((DTC, index) => {
            if (DTC.DTCcode.indexOf('Airbag') === 0)
            {
                DTC['id']       = idx++;
                DTC['module']   = 'Airbag';
                
            }
            else if (DTC.DTCcode.indexOf('Engine') === 0)
            {
                DTC['id']       = idx++;
                DTC['module']   = 'Engine';

            }
            else if (DTC.DTCcode.indexOf('CGW') !== 0)
            {
                DTC['id']       = idx++;
                DTC['module']   = 'CGW';

            }
            
            processedData.push(DTC);
        })
    }
    
    return processedData;
};

function processDeviceData(rawData)
{
    let processedData   = [];
    
    if (rawData.length)
    {
        rawData.forEach((deviceData, index) => {
            deviceData['id'] = index;
            processedData.push(deviceData);
        })
    }
    
    return processedData;
};

function processUserDeviceData(rawData)
{
    let processedData   = [];
    
    if (rawData.length)
    {
        rawData.forEach((deviceData, index) => {
            deviceData.carID['id'] = index;
            
            processedData.push(deviceData.carID);
        })
    }
    
    return processedData;
};

function DTCSetting(props)
{
    const activedComponent          = props.activedComponent;

    const [DTCData, setDTCData]     = useState([]);
    const [openInfo, setOpenInfo]   = useState(false);
    const [openEdit, setOpenEdit]   = useState({
        open: false,
        data:
        {
            DTCcode:        '',
            description:    '',
            severity:       '',
        }
    });
    
    const gridStyle = { fontSize: 13 }

    const columns = [
        {
            field: 'DTCcode',
            headerName: 'DTC code',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 5,
            headerAlign: 'center'
        },
        {
            field: 'severity',
            headerName: 'Severity',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (values) => {
                if (values.row.severity === '3-high')
                {
                    return (
                        <div className='box-error'>
                            <FontAwesomeIcon icon={faTriangleExclamation} />
                            <div className='box-title'>High</div>
                        </div>
                    )
                }
                else if (values.row.severity === '2-medium')
                {
                    return (
                        <div className='box-warning'>
                            <FontAwesomeIcon icon={faExclamation} />
                            <div className='box-title'>Medium</div>
                        </div>
                    )
                }
                else if (values.row.severity === '1-low')
                {
                    return (
                        <div className='box-info'>
                            <FontAwesomeIcon icon={faCircleExclamation} />
                            <div className='box-title'>Low</div>
                        </div>
                    )
                }
            }
        },
        {
            field: 'module',
            headerName: 'Module',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (values) => {
                if (values.row.module === 'Airbag')
                {
                    return (
                        <div className='box-module box-green'>Airbag</div>
                    )
                }
                else if (values.row.module === 'CGW')
                {
                    return (
                        <div className='box-module box-orange'>Central Gateway</div>
                    )
                }
                else if (values.row.module === 'Engine')
                {
                    return (
                        <div className='box-module box-yellow'>Engine</div>
                    )
                }
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            sortable: false,
            renderCell: (values) => {
                return (
                    <div className='action-container'>
                        <button 
                            className='action-button box-yellow'
                            onClick={() => setOpenEdit({open: true, data: values.row})}
                        >
                            <FontAwesomeIcon icon={faPen} />
                            <div className='action-info'>Edit</div>
                        </button>
                        <button 
                            className='action-button box-orange'
                            onClick={() => handleDeleteDTC(values.row)}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            <div className='action-info'>Remove</div>
                        </button>
                    </div>
                )
            }
        }
    ];

    const getDTCsData = async () => {
        const response  = await fetch('/api/dtc');
        const result    = await response.json();

        setDTCData(processDTCData(result));
    }

    const handleDeleteDTC = (DTCData) => {
        const toastId = toast.loading("Deleting...");

        fetch(`/api/dtc/${DTCData._id}`, { method: 'DELETE' })
        .then((respose) => {
            respose.json()
            .then((result) => {
                if (result.status === 'success')
                {
                    getDTCsData();
                    toast.update(toastId, {
                        render: result.message,
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
                else
                {
                    toast.update(toastId, {
                        render: result.message,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
            })
        })
        .catch((respose) => {
            respose.json()
            .then((result) => {
                toast.update(toastId, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                })
            })
        })
    }

    useEffect(() => {
        getDTCsData();
    }, []);

    return (
        <>
            <div className='setting-table-container'>
                {
                    (DTCData.length !== 0) ? 
                    (<>
                        <DataGrid 
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'module', sort: 'desc' }],
                                },
                            }}
                            rows    = { DTCData }
                            columns = { columns }
                            style   = { gridStyle }
                            autoPageSize 
                            disableRowSelectionOnClick
                        />
                    </>
                    ) :
                    (
                        <div className='diagnostic-info-container'>
                            <FontAwesomeIcon icon={faFolderOpen} />
                            <div className='diagnostic-info-title'>No data found</div>
                        </div>
                    )
                }
            </div>
            <div className='setting-button-container'>
                {
                    (activedComponent === 3) ?
                    (
                        <Button onClick={() => setOpenInfo(true)}
                            color   = 'green'
                            icon    = {faPlus}
                            title   = 'New DTC data'
                        />
                    ):
                    (<></>)
                }
            </div>
            <Popup
                open={openEdit.open}
                closeOnDocumentClick
                onClose={() => {
                    setOpenEdit({...openEdit, open: false})
                }}
            >
                <UpdateDTCPopup data={openEdit.data} />
            </Popup>
            <Popup
                open={openInfo}
                closeOnDocumentClick
                onClose={() => setOpenInfo(false)}
            >
                <NewDTCPopup />
            </Popup>
        </>
    )
}

function DeviceSetting(props)
{
    const activedComponent          = props.activedComponent;

    const [DevicesData, setDevicesData] = useState([]);
    const [openInfo,    setOpenInfo]    = useState(false);
    const [openEdit,    setOpenEdit]    = useState({
        open: false,
        data:
        {
            modelName: ''
        }
    });

    const gridStyle = { fontSize: 13 }

    const columns = [
        {
            field: '_id',
            headerName: 'id',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'MQTTusername',
            headerName: 'Username',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'MQTTpassword',
            headerName: 'Password',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'licensePlate',
            headerName: 'License plate',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'modelName',
            headerName: 'Model Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'owner',
            headerName: 'Owner',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (values) => {
                return (
                    <>
                    {
                        (values.row.owner) ?
                        (<>{values.row.owner.ownerID.firstname} {values.row.owner.ownerID.lastname}</>) :
                        (<></>)
                    }
                    </>
                )
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            sortable: false,
            renderCell: (values) => {
                return (
                    <div className='action-container'>
                        <button 
                            className='action-button box-yellow'
                            onClick={() => setOpenEdit({open: true, data: values.row})}
                        >
                            <FontAwesomeIcon icon={faPen} />
                            <div className='action-info'>Edit</div>
                        </button>
                        <button 
                            className='action-button box-orange'
                            onClick={() => handleDeleteDevice(values.row)}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            <div className='action-info'>Remove</div>
                        </button>
                    </div>
                )
            }
        }
    ];

    const getDevicesData = async () => {
        const response  = await fetch('/api/car');
        const result    = await response.json();

        setDevicesData(processDeviceData(result));
    }

    const handleDeleteDevice = (DeviceData) => {
        const toastId = toast.loading("Deleting...");

        fetch(`/api/car/${DeviceData._id}`, { method: 'DELETE' })
        .then((respose) => {
            respose.json()
            .then((result) => {
                if (result.status === 'success')
                {
                    getDevicesData();
                    toast.update(toastId, {
                        render: result.message,
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
                else
                {
                    getDevicesData();
                    toast.update(toastId, {
                        render: result.message,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
            })
        })
        .catch((respose) => {
            respose.json()
            .then((result) => {
                toast.update(toastId, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                })
            })
        })
    }

    useEffect(() => {
        getDevicesData();
    }, []);

    return (
        <>
            <div className='setting-table-container'>
                {
                    (DevicesData.length !== 0) ? 
                    (<>
                        <DataGrid 
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'module', sort: 'desc' }],
                                },
                            }}
                            rows    = { DevicesData }
                            columns = { columns }
                            style   = { gridStyle }
                            autoPageSize 
                            disableRowSelectionOnClick
                        />
                    </>
                    ) :
                    (
                        <div className='diagnostic-info-container'>
                            <FontAwesomeIcon icon={faFolderOpen} />
                            <div className='diagnostic-info-title'>No data found</div>
                        </div>
                    )
                }
            </div>
            <div className='setting-button-container'>
                {
                    (activedComponent === 2) ?
                    (
                        <Button onClick={() => setOpenInfo(true)}
                            color   = 'green'
                            icon    = {faPlus}
                            title   = 'New device'
                        />
                    ):
                    (<></>)
                }
            </div>
            <Popup
                open={openEdit.open}
                closeOnDocumentClick
                onClose={() => {
                    setOpenEdit({...openEdit, open: false})
                }}
            >
                <UpdateDevice deviceInfos={openEdit.data} />
            </Popup>
            <Popup
                open={openInfo}
                closeOnDocumentClick
                onClose={() => setOpenInfo(false)}
            >
                <NewDevice />
            </Popup>
        </>
    )
}

function UserSetting(props)
{
    const activedComponent          = props.activedComponent;

    const { token }                 = useAuth();
    const [UsersData, setUsersData] = useState([]);
    const [openInfo,  setOpenInfo]  = useState(false);

    const gridStyle = { fontSize: 13 }

    const columns = [
        {
            field: 'username',
            headerName: 'User name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'firstname',
            headerName: 'First name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'lastname',
            headerName: 'Last name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'roles',
            headerName: 'Role',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (values) => {
                if (values.row.roles === 'user')
                {
                    return (
                        <div className='box-module box-green'>User</div>
                    )
                }
                else if (values.row.roles === 'admin')
                {
                    return (
                        <div className='box-module box-orange'>Admin</div>
                    )
                }
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            sortable: false,
            renderCell: (values) => {
                return (
                    <div className='action-container'>
                        <button 
                            className='action-button box-orange'
                            onClick={() => handleDeleteUser(values.row)}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            <div className='action-info'>Remove</div>
                        </button>
                    </div>
                )
            }
        }
    ];

    const getUsersData = async () => {
        const response  = await fetch('/api/admin/all', {
            headers: {
                'x-access-token': JSON.parse(token).accessToken
            }
        });
        const result    = await response.json();

        setUsersData(processDeviceData(result));
    }

    const handleDeleteUser = (UserData) => {
        const toastId = toast.loading("Deleting...");

        fetch(`/api/admin/${UserData._id}`, {
            method: 'DELETE',
            headers: {
                'x-access-token': JSON.parse(token).accessToken
            }
        })
        .then((respose) => {
            respose.json()
            .then((result) => {
                if (result.status === 'success')
                {
                    getUsersData();
                    toast.update(toastId, {
                        render: result.message,
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
                else
                {
                    getUsersData();
                    toast.update(toastId, {
                        render: result.message,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
            })
        })
        .catch((respose) => {
            respose.json()
            .then((result) => {
                toast.update(toastId, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                })
            })
        })
    }

    useEffect(() => {
        getUsersData();
    }, []);

    return (
        <>
            <div className='setting-table-container'>
                {
                    (UsersData.length !== 0) ? 
                    (<>
                        <DataGrid 
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'module', sort: 'desc' }],
                                },
                            }}
                            rows    = { UsersData }
                            columns = { columns }
                            style   = { gridStyle }
                            autoPageSize 
                            disableRowSelectionOnClick
                        />
                    </>
                    ) :
                    (
                        <div className='diagnostic-info-container'>
                            <FontAwesomeIcon icon={faFolderOpen} />
                            <div className='diagnostic-info-title'>No data found</div>
                        </div>
                    )
                }
            </div>
            <div className='setting-button-container'>
                {
                    (activedComponent === 1) ?
                    (
                        <Button onClick={() => setOpenInfo(true)}
                            color   = 'green'
                            icon    = {faPlus}
                            title   = 'New user'
                        />
                    ):
                    (<></>)
                }
            </div>
            <Popup
                open={openInfo}
                closeOnDocumentClick
                onClose={() => setOpenInfo(false)}
            >
                <NewUser />
            </Popup>
        </>
    )
}

function DeviceUserSetting(props)
{
    const activedComponent          = props.activedComponent;
    const { token }                 = useAuth();

    const [DevicesData, setDevicesData] = useState([]);
    const [openInfo,    setOpenInfo]    = useState(false);
    const [openEdit,    setOpenEdit]    = useState({
        open: false,
        data: {}
    });

    const gridStyle = { fontSize: 13 }

    const columns = [
        {
            field: 'vin',
            headerName: 'VIN',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'licensePlate',
            headerName: 'License plate',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'modelName',
            headerName: 'Model Name',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'connectionStatus',
            headerName: 'Connection status',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (values) => {
                if (values.row.connectionStatus === 'offline')
                {
                    return (
                        <div className='box-error'>
                            <FontAwesomeIcon icon={faLinkSlash} />
                            <div className='box-title'>Offline</div>
                        </div>
                    )
                }
                else if (values.row.connectionStatus === 'online')
                {
                    return (
                        <div className='box-info'>
                            <FontAwesomeIcon icon={faLink} />
                            <div className='box-title'>Online</div>
                        </div>
                    )
                }
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            sortable: false,
            renderCell: (values) => {
                return (
                    <div className='action-container'>
                        <button 
                            className='action-button box-yellow'
                            onClick={() => setOpenEdit({open: true, data: values.row})}
                        >
                            <FontAwesomeIcon icon={faPen} />
                            <div className='action-info'>Edit</div>
                        </button>
                        <button 
                            className='action-button box-orange'
                            onClick={() => handleDeleteDevice(values.row)}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            <div className='action-info'>Remove</div>
                        </button>
                    </div>
                )
            }
        }
    ];

    const getDevicesData = async () => {
        const response  = await fetch('/api/car/user/' + JSON.parse(token).id, {
            headers: {
                'x-access-token': JSON.parse(token).accessToken
            }
        });
        const result    = await response.json();

        setDevicesData(processUserDeviceData(result.car));
    }

    const handleDeleteDevice = (DeviceData) => {
        const toastId = toast.loading("Deleting...");

        fetch(`/api/car/${DeviceData._id}/user/${JSON.parse(token).id}`, { method: 'DELETE' })
        .then((respose) => {
            respose.json()
            .then((result) => {
                if (result.status === 'success')
                {
                    getDevicesData();
                    toast.update(toastId, {
                        render: result.message,
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
                else
                {
                    getDevicesData();
                    toast.update(toastId, {
                        render: result.message,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    })
                }
            })
        })
        .catch((respose) => {
            respose.json()
            .then((result) => {
                toast.update(toastId, {
                    render: result.message,
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                })
            })
        })
    }

    useEffect(() => {
        getDevicesData();
    }, []);

    return (
        <>
            <div className='setting-table-container'>
                {
                    (DevicesData.length !== 0) ? 
                    (<>
                        <DataGrid 
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'module', sort: 'desc' }],
                                },
                            }}
                            rows    = { DevicesData }
                            columns = { columns }
                            style   = { gridStyle }
                            autoPageSize 
                            disableRowSelectionOnClick
                        />
                    </>
                    ) :
                    (
                        <div className='diagnostic-info-container'>
                            <FontAwesomeIcon icon={faFolderOpen} />
                            <div className='diagnostic-info-title'>No data found</div>
                        </div>
                    )
                }
            </div>
            <div className='setting-button-container'>
                {
                    (activedComponent === 2) ?
                    (
                        <Button onClick={() => setOpenInfo(true)}
                            color   = 'green'
                            icon    = {faPlus}
                            title   = 'Add device'
                        />
                    ):
                    (<></>)
                }
            </div>
            <Popup
                open={openEdit.open}
                closeOnDocumentClick
                onClose={() => {
                    setOpenEdit({...openEdit, open: false})
                }}
            >
                <UpdateUserDevicePopup data={openEdit.data} />
            </Popup>
            <Popup
                open={openInfo}
                closeOnDocumentClick
                onClose={() => setOpenInfo(false)}
            >
                <NewUserDevicePopup />
            </Popup>
        </>
    )
}

export { DTCSetting, DeviceSetting, UserSetting, DeviceUserSetting };
