import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCarSide } from '@fortawesome/free-solid-svg-icons';

import './diagnosticItem.css';

function DiagnosticButton(props) {
    const { DiagnosticItemContent } = props;

    return (
        <div className='button-container'>
            <div>
                <img src={ DiagnosticItemContent.icon } className='button-left-icon' alt='button icon' />
            </div>
            <div>
                <div className='button-title'>{ DiagnosticItemContent.title }</div>
                <div className='button-content'>{ DiagnosticItemContent.content }</div>
            </div>
            <div>
                <FontAwesomeIcon icon={ faCaretRight } />
            </div>
        </div>
    )
};

function DiagnosticCar(props) {
    const isActive          = props.isActive;
    const CarItemContent    = props.CarItemContent;

    return (
        <div className={isActive === true ? 'car-item-container car-item-actived' : 'car-item-container'}>
            <div className='car-left-icon'>
                <FontAwesomeIcon icon={faCarSide} />
            </div>
            <div className='car-item-content-central'>
                <div className='car-item-bold'>{ CarItemContent.licensePlate }</div>
                <div className='car-item-light'>{ CarItemContent.modelName }</div>
            </div>
            <div className='car-item-content-right'>
                <div className='car-item-light'>Connection status</div>
                <div className='car-item-bold'>{ CarItemContent.connectionStatus }</div>
            </div>
        </div>
    )
}

export { DiagnosticButton, DiagnosticCar };
