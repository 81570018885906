import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Button.css'

function Button(props)
{
    let buttonClassName;

    if (props.color === 'green')
    {
        buttonClassName = 'button button-green';
    }
    else if (props.color === 'orange')
    {
        buttonClassName = 'button button-orange';
    }
    else if (props.color === 'yellow')
    {
        buttonClassName = 'button button-yellow';
    }

    return (
        <button className={ buttonClassName } onClick={ props.onClick }>
            <div className='button-icon'>
                <FontAwesomeIcon icon={ props.icon } />
            </div>
            <div className='button-title'>{ props.title }</div>
        </button>
    )
}

export { Button };
