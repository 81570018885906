import React from 'react';

import { HeaderBar } from '../../components/headerbar/Headerbar';
import '../../App.css';

function Triplogs(props) {
    return (
        <>
            <HeaderBar headerBarContent="Triplogs"/>
            <div className='page__mid-content-container'>
            </div>
        </>
    );
}

export default Triplogs;