import WebRoutes            from './routes/routes';
import { ToastContainer }   from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
    return (
        <div className='App'>
            <ToastContainer
                position='top-right'
                autoClose={3500}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='colored'
            />
            <WebRoutes />
        </div>
    );
}

export default App;
